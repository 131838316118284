<template>
    <v-container class="session-container fill-height" fluid>
        <v-row  justify="center" align="center">
        <v-col cols="12" sm="8" md="4">

            <v-col cols="12" class="d-flex justify-center">
                <h1 class="white--text">✪ AuditEngine</h1>
            </v-col>

            <v-col cols="12" class="d-flex justify-center">
            <h3>We are Sorry, page not found!</h3>
            </v-col>

            <div slot="session-body">
                <v-flex xs12 md12 lg12 class="text-center" style="margin-top: 50px;">
                    <span class="fs-13 dark-grey-text "></span>
                </v-flex>
            </div>
        </v-col>
        </v-row>
    </v-container>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    name: 'PageNotFound',
    components: {},
    mounted() {
    },
  }
</script>